import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { toastError } from '@/plugins/sweetalert2.js'

// const baseURL = 'http://192.168.209.82:8003/api'
// const baseURL = 'https://api.porcuotas.app/api'
const baseURL = 'https://testapi.porcuotas.app/api'

const bdProdConexion = false

axios.defaults.baseURL = baseURL

axios.interceptors.request.use(async (config) => {
    config.headers.bdProdConexion = bdProdConexion
    return config
}, err => {
    return Promise.reject(err)
});

axios.interceptors.response.use(function (response) {
    return response;
    }, function (error) {
    switch(error.response.status){
        case 422:
            {
                let message = ''
                for (const property in error.response.data.errors) {
                    message += error.response.data.errors[property] + ' '
                }
                toastError.fire({
                    title: message
                })
            }
        break
        case 500:
            {
                toastError.fire({
                    title: error.response.data.message
                })
            }
        break
        case 404:
            {
                if (error.response.data.message) {
                    toastError.fire({
                        title: error.response.data.message
                    })
                }
            }
        break
    }
    return Promise.reject(error)
});

Vue.use(VueAxios, axios)